import RightPanel from "../../../Components/RightPanel";
import Resources from "./Resources";
import { Layout, theme } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
const { Content } = Layout;

const Index = () => {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ background: colorBgContainer }}>
      <DndProvider backend={HTML5Backend}>
        <Content style={{
          margin: '24px 16px',
          padding: 24,
          minHeight: 280,
          background: '#fff',
          borderRadius: borderRadiusLG,
        }}>
          <Resources showSelected />
        </Content>
        <RightPanel type="resource" />
      </DndProvider>
    </Layout>
  );
};

export default Index;
